<template>
  <div class="AllScreenings">
    <div class="InnerPage">
      <div class="NormalPageTitle">
        {{
          mixWB('ALL_PROJECTS')
        }}
      </div>

      <div class="Top">
        <!-- Screener -->
        <div class="FilterWrap Screener">
          <span class="Title">{{ mixWB('USER') }}</span>
          <SceenerFilter @option-select="onOptionSelect" />
        </div>

        <!-- Status -->
        <div class="FilterWrap Status">
          <span class="Title">{{ mixWB('STATUS') }}</span>
          <div class="StatusOptions">
            <template v-for="option in statusOptions">

              <div
                :key="option.value"
                v-if="!isDemoUser || (isDemoUser && option.value !== 'archived')"
                class="StatusItem"
                :class="{ IsSelected: option.value === selectedStatus && searchTerm.length < 3 }"
                @click="onStatusOptionClick(option.value)">
                <span>{{ option.text }}</span>
              </div>
            </template>
          </div>
        </div>

        <!-- Search -->
        <div
          ref="Search"
          class="FilterWrap Search">
          <span class="Title">{{ mixWB('SEARCH') }}</span>
          <div class="SearchWrap">
            <InputField
              :name="'search-term'"
              :value="searchTerm"
              :placeholder="mixWB('ALL_PROJECTS_SEARCH_TEXT')"
              @focus="onSearchTermFocus"
              @on-update="onSearchTermUpdate" />
            <div
              class="ClearIcon"
              v-if="searchTerm.length"
              @click="searchTerm = ''">
              <CloseIcon />
            </div>
          </div>
        </div>
      </div>

      <DeprecatedInfo />

      <!-- List view -->
      <div
        v-if="allScreeningsLoaded"
        class="ScreeningListWrap">
        <span class="Title">
          {{ mixWB('VIEWING') }} ({{ mutatedAllScreenings.length }})
        </span>
        <div class="List">
          <div
            class="ItemWrap"
            v-for="screening in mutatedAllScreenings"
            :key="screening.id">
            <ScreeningItem :item="screening" />
          </div>
        </div>

        <EmptyList v-if="!mutatedAllScreenings.length"/>
      </div>

      <!-- Loading -->
      <div
        class="LoadingWrap"
        v-else>
        <Circular :size="'medium'" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CloseIcon from '@/assets/svg/close.svg?inline'
import SceenerFilter from '@/components/ScreeningItems/SceenerFilter.vue'
import ScreeningItem from '@/components/ScreeningItems/ScreeningItem.vue'
import InputField from '@/components/FormElements/InputField.vue'
import { keys } from '@/globals/javascript/_util/keys'
import EmptyList from '@/components/ScreeningItems/EmptyList.vue'
import Circular from '@/components/Progress/Circular.vue'
import { scrollTo } from '@/globals/javascript/_util/util'
import DeprecatedInfo from '@/components/DeprecatedInfo.vue'

export default {
  name: 'AllScreenings',
  data() {
    return {
      selectedUserID: null,
      selectedStatus: 'created',
      searchTerm: '',
      statusOptions: [
        { value: 'created', text: this.mixWB('CREATED') },
        { value: 'completed', text: this.mixWB('COMPLETED') },
        { value: 'archived', text: this.mixWB('ARCHIVED') },
      ],
    }
  },
  computed: {
    ...mapGetters([
      'allScreenings',
      'allScreeningsLoaded',
      'currentRoute',
      'isDemoUser',
    ]),
    mutatedAllScreenings() {
      const searchTerm = this.searchTerm.toLowerCase()

      // Wait until filter is loaded
      if (this.selectedUserID === null) {
        return []
      }

      // Removed unstarted and archived screenings
      let filteredScreenings = this.allScreenings.filter((screening) => {
        if (!screening.isStarted && screening.isArchived) {
          return false
        }
        return true
      })

      // Filter by user
      if (
        this.isDemoUser
        || (searchTerm.length < 3 && this.selectedUserID)
      ) {
        filteredScreenings = filteredScreenings.filter((x) => x.userID === this.selectedUserID)
      }

      // Filter by screening status
      if (searchTerm.length < 3) {
        if (this.selectedStatus === 'created') {
          filteredScreenings = filteredScreenings.filter((x) => !x.isCompleted && !x.isArchived)
        }
        if (this.selectedStatus === 'completed') {
          filteredScreenings = filteredScreenings.filter((x) => x.isCompleted && !x.isArchived)
        }
        if (this.selectedStatus === 'archived') {
          filteredScreenings = filteredScreenings.filter((x) => x.isArchived)
        }
      }

      // Filter by search
      else {
        filteredScreenings = filteredScreenings.filter((screening) => {
          // Address
          const address = `${
            screening.address.address
          }, ${
            screening.address.postalCode
          } ${
            screening.address.city
          }`.toLowerCase()
          if (address.includes(searchTerm)) {
            return true
          }

          // Status
          const status = screening.getStatus().text.toLowerCase()
          if (status?.includes?.(searchTerm)) {
            return true
          }

          // Builder
          if (screening.builderFullName?.toLowerCase?.().includes(searchTerm)) {
            return true
          }

          // Case number
          if (screening.getCaseNumber?.().toLowerCase?.().includes(searchTerm)) {
            return true
          }

          // Sampling ID
          if (screening.getSamplingID?.().toLowerCase?.().includes(searchTerm)) {
            return true
          }

          // Enterprise ID
          if (screening.enterpriseID?.toLowerCase?.().includes(searchTerm)) {
            return true
          }

          // Screening owner
          const screeningOwnerName = screening.screeningOwnerHUBAccount?.name ?? ''
          if (screeningOwnerName.toLowerCase().includes(searchTerm)) {
            return true
          }
          return false
        })
      }

      return filteredScreenings
    },
  },
  methods: {
    onOptionSelect(userID) {
      this.selectedUserID = userID
    },
    onStatusOptionClick(value) {
      this.searchTerm = ''
      this.selectedStatus = value
      window.sessionStorage.setItem(keys.SS_SCREENING_LIST_STATUS, value)
    },
    onSearchTermFocus() {
      if (window.innerWidth < 768) {
        scrollTo({
          element: this.$refs.Search,
          offset: -25,
        })
      }
    },
    onSearchTermUpdate({ value }) {
      this.searchTerm = value
    },
    setSelectedStatusOnLoad() {
      let status = this.$route.query?.status
      status = status
        && ['created', 'completed', 'archived'].includes(status)
        ? status
        : ''

      if (status) {
        this.selectedStatus = status
        window.sessionStorage.setItem(keys.SS_SCREENING_LIST_STATUS, status)
        return
      }

      const savedStatus = window.sessionStorage.getItem(keys.SS_SCREENING_LIST_STATUS)
      status = savedStatus
        && ['created', 'completed', 'archived'].includes(savedStatus)
        ? savedStatus
        : ''

      this.selectedStatus = status || 'created'
    },
  },
  components: {
    CloseIcon,
    SceenerFilter,
    ScreeningItem,
    InputField,
    EmptyList,
    Circular,
    DeprecatedInfo,
  },
  created() {
    this.setSelectedStatusOnLoad()
  },
}
</script>

<style lang="stylus" scoped>
  .AllScreenings
    page-wrap-outer-1()

  .InnerPage
    page-wrap-inner-2()

  .Top
    display flex
    flex-wrap wrap
    width 100%

  .FilterWrap
    .Title
      padding-bottom 3px
      text-transform uppercase
      font-size 0.875rem
    +above($wide)
      &.Screener
        width calc(25% - 20px)
        margin-right 20px
      &.Status
        margin-right 20px
      &.Search
        flex-grow 1
    +below($wide)
      &.Screener
        width 100%
      &.Status
        margin-right 20px
      &.Search
        flex-grow 2
    +below($tablet)
      justify-content space-between
      &.Status
        width 100%
      &.Search
        width calc(50% - 10px)
    +below($phablet)
      &.Search
        width 100%
        margin-right 0

  .StatusOptions
    display flex
    flex-wrap wrap
    margin 0px -5px
    .StatusItem
      display flex
      align-items center
      justify-content center
      min-width 70px
      min-height 40px
      padding 5px 10px
      margin 0px 5px 10px
      background-color $color_grey_lightest
      border 1px solid $color_grey_lighter
      cursor pointer
      span
        text-align center
        text-transform uppercase
        font-size 0.875rem
      &.IsSelected
        background-color $color_grey
        border-color $color_grey
        span
          color #fff

  .SearchWrap
    position relative
    .ClearIcon
      position absolute
      top 0
      right 0
      box(40px)
      flex-center-children()
      padding 14px
      cursor pointer

  .ScreeningListWrap
    padding-top 20px
    .Title
      text-transform uppercase
      font-weight bold
      padding-bottom 10px
    .List
      display flex
      flex-wrap wrap
      margin 0 -5px 10px
    .ItemWrap
      margin 0px 5px 15px
      width calc(33.33% - 10px)
      +below($desktop)
        width calc(50% - 10px)
      +below($tablet)
        width 100%

  .LoadingWrap
    position relative
    height 400px
    +below($phablet)
      height 200px

</style>
