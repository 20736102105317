<template>
  <div class="DeprecatedInfo">
    <span class="Title">{{ mixWB('W: Milva Pro version 3 er nu tilgængelig ') }}</span>
    <span
      v-for="(text, index) in infoTexts"
      :key="index"
      v-html="text"/>
  </div>
</template>

<script>
import { ENV } from '@/globals/javascript/_util/env'

export default {
  name: 'DeprecatedInfo',
  data() {
    const v3Link = ENV.isStage || ENV.isDevelopment ? 'https://dev-pro.milva.io' : 'https://pro.milva.io'
    const v3LinkText = ENV.isStage || ENV.isDevelopment ? 'dev-pro.milva.io' : 'pro.milva.io'

    return {
      infoTexts: [
        this.mixWB('W: Dette er <b>Milva Pro version 2</b>. Denne version er forældet og holdes ikke længere ved lige.'),
        this.mixWB('W: Det er ikke længere muligt at oprette nye projekter, dog kan man stadig tilgå eksisterende for en periode.'),
        '',
        this.mixWB('W: Tilgå i stedet Milva Pro version 3 via <a href="%s">%s</a>.', [v3Link, v3LinkText]),
        '',
        this.mixWB('W: Har du spørgsmål kan du kontakte os på tlf: 40 70 90 23.'),
      ],
    }
  },
}
</script>

<style lang="stylus" scoped>
  .DeprecatedInfo
    margin-top 15px
    padding 15px
    border 1px solid $color_yellow
    background-color $color_yellow_lightest
    span
      color $color_yellow_darkest
      color $color_grey
      padding-bottom 5px
    .Title
      font-size 1.25rem
      padding-bottom 15px
    a
      font-weight bold
</style>

<style lang="stylus">
  .DeprecatedInfo
    a
      font-weight bold
      color $color_primary
      text-decoration underline
</style>
